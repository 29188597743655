import React,{ useEffect, useState } from 'react';
import { connect, } from 'react-redux'
import { fetchAllPost, updatePost } from "../../../redux";
import { Link } from 'react-router-dom';
import { getAllPosts } from '../../../redux/post/postSelectors';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import UpdateIcon from '@mui/icons-material/Update';
import { Button } from '@mui/material';
import Pagination from "@mui/material/Pagination";
import { formatDate } from '../../../Utils/stringFunctions';

function PostTable(props) {
  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState(null);
  const {post, fetchAllPost, updatePost} = props;
  const mediaBaseURL = process.env.REACT_APP_MEDIA_BASE_URL;
  const limit = 6;
  const post_count = post.post?.data?.count ? parseInt(post.post?.data?.count) : 0;
  const page_count = Math.ceil(post_count / limit);
  
  useEffect(() => {
    setPosts(post.post?.data?.results);
  }, [post])

  const handlePublish = async (postId, isPostPublish) => {
    // console.log({postId, isPostPublish})
    await updatePost({
      id: postId,
      value: { post_publish: !isPostPublish },
    }).then((res) => {
      console.log({res})
      if(res.payload.status === 200) {
        setPosts(posts.map((post) => {
          if(post.id === postId) {
            return {
              ...post,
              post_publish: !isPostPublish
            }
          }
          return post;
        }))
      }
    }
    );
  };

  useEffect(() => {
    fetchAllPost({limit: limit, offset: 0});
  }, [])

  const handleChange = (e, p) => {
    setPage(p);
    fetchAllPost({limit: limit,offset: (p - 1) * limit});
  };
  

  return (
    <>
    {posts && posts.map((row) => (
    <Card key={row?.id} sx={{ display: 'flex', marginBottom: '1rem', justifyContent: "space-between" }}>
      <CardMedia
        component="img"
        sx={{ width: "auto", height: "100%", maxHeight: "150px", maxWidth: "250px"}}
        image={mediaBaseURL+row.post_image}
        alt="Live from space album cover"
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h6">
            {row?.post_title}
          </Typography>
          <Box
          sx={{
              display: 'flex'
            }}
          >
          <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: "flex-start",
          }}
          >
            <PersonIcon/>
          <Typography variant="subtitle1" color="text.secondary" component="div" sx={{marginLeft: "10px"}}>
            {row?.post_author}
          </Typography>
          </Box>
          <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: "flex-start",
            marginLeft: "1rem"
          }}
          >
            <UpdateIcon/>
          <Typography variant="subtitle1" color="text.secondary" component="div" sx={{marginLeft: "10px"}}>
            {formatDate(row?.post_updated_date)}
          </Typography>
          </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end"}}>
          <Button variant="text" component={Link} to={'/dashboard/post/'+row.id} sx={{marginRight: '10px'}}>Edit</Button>
          <Button variant="text" sx={{marginRight: '10px'}} onClick={() => handlePublish(row.id,row.post_publish)}>{row?.post_publish ? "Unpublish": "Publish"}</Button>
          {/* <Button variant="text" color="error" component={Link} to={'/dashboard/post/'+row.id}>Delete</Button> */}
        </Box>
      </CardContent>
        
      </Box>
      
    </Card>
    ))}
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      marginTop: '1rem'
    }}
    >
          <Pagination
            count={page_count}
            variant="outlined"
            onChange={handleChange}
            page={page}
          />
      </Box>
    </>
  );
  

  // return (
  //   <React.Fragment>
  //     <Title>Recent Posts</Title>
  //     <Table size="small">
  //       <TableHead>
  //         <TableRow>
  //           <TableCell>Title</TableCell>
  //           <TableCell>Author</TableCell>
  //           <TableCell>Updated By</TableCell>
  //           <TableCell>Slug</TableCell>
  //           <TableCell align="right">Views</TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {post.post?.data?.results.map((row) => (
  //           <TableRow key={row.id}>
  //             <TableCell >
  //               <Link to={'/dashboard/post/'+row.id}>{row.post_title}</Link>
                
  //               </TableCell>
  //             <TableCell>{row.post_author}</TableCell>
  //             <TableCell>{row.post_updated_by}</TableCell>
  //             <TableCell>{row.post_slug}</TableCell>
  //             <TableCell align="right">{row.post_view_count}</TableCell>
  //           </TableRow>
  //         ))}
  //       </TableBody>
  //     </Table>
  //     <Link color="primary" to="/dashboard/post" sx={{ mt: 3 }}>
  //       See more posts
  //     </Link>
  //   </React.Fragment>
  // );
}

const mapStateToProps = state => {
  return {
    post: getAllPosts(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchAllPost: (data) => dispatch(fetchAllPost(data)),
    updatePost: ({ id, value }) => dispatch(updatePost({ id, value })),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostTable)

