import React from 'react'
import { mediaBaseURL } from '../../../Utils/constants'
import { isURL } from '../../../Utils/stringFunctions';

const CustomImage = ({src, alt, className, ...props}) => {
    return (
         isURL(src) ? <img src={src} alt={alt} className={className} /> : <img src={mediaBaseURL+src} alt={alt} />
    )
}

export default CustomImage