const defaultTags = [
    "Python",
    "Django",
    "PHP",
    "CSS",
    "Javascript",
    "HTML",
    "React",
    "React.js",
    "MERN stack",
    "LAMP stack",
    "Redux",
    "redux-thunk"
  ];

export default defaultTags;