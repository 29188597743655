import React from 'react';
import {QRCodeCanvas} from 'qrcode.react';

function TOTPQRCode({ QRCode }) {
  return (
    <div>
      <QRCodeCanvas value={QRCode} size={256}/>
    </div>
  );
}

export default TOTPQRCode;