import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PostTable from "./Post/PostTable";


function DashboardContent(props) {
  return (
    <>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <PostTable />
        </Paper>
      </Grid>
    </>
  );
}

export default DashboardContent;