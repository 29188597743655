import TitleBar from '../title-bar/title-bar';
import Markdown from "markdown-to-jsx"
import { useMarkdown } from '../../providers/markdown-provider';
import './preview.css';
import Code from './code';
import { useState } from 'react';
import CustomImage from './CustomImage';
import { ZoomOut } from '../../assets/icons';
import { ZoomInMap, ZoomOutMap } from '@mui/icons-material';

const Preview = () => {
  const [markdown, setMarkdown,  zoomIn, setZoomIn] = useMarkdown();
  const [isDark, setIsDark] = useState(true);
  
  const changeZoom = () => {
    setZoomIn(!zoomIn);

  }

  return (
    <div className="preview">
      <TitleBar title="Preview" aside={<div onClick={changeZoom}>{zoomIn ? <ZoomOutMap/> : <ZoomInMap/> }</div>}/>
      <div className="preview__scroll">
      <Markdown
            options={{
              overrides: {
                pre : {
                  component: Code,
                  props: {
                    isDark,
                    setIsDark
                  }
                },
                img : {
                  component: CustomImage
                }              
              }
            }}
          >
            {markdown}
          </Markdown>
           
           
      </div>
    </div>
  );
}


export default Preview;
