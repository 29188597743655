import './main-layout.css';
import { useMarkdown } from '../../providers/markdown-provider';

const MainLayout = ({ children }) => {
  const [ , , zoomIn] = useMarkdown();
  
  return (
  <main className={`mainLayout${ !zoomIn ? " main__layout__full" : ""}`}>
    {children}
  </main>
)};

MainLayout.Column = ({ children }) => (
  <div className="mainLayout__col">
    {children}
  </div>
);

export default MainLayout;
