import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { connect } from "react-redux";
import Image from "mui-image";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { requestState } from "../../../redux/type/type";
import { getAllImages } from "../../../redux/gallery/gallerySelector";
import { fetchAllImages, uploadImage, showAlert } from "../../../redux";

const limit = 10;

const Gallery = (props) => {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [imageData, setImageData] = useState({
    title: "",
    height: 0,
    width: 0,
    media: "",
  });
  // const [selectedFiles, setSelectedFiles] = useState(undefined);
  // const [currentFile, setCurrentFile] = useState(undefined);
  // const [progress, setProgress] = useState(0);
  // const [message, setMessage] = useState("");
  // const [fileInfos, setFileInfos] = useState([]);
  // const [isError, setIsError] = useState(false);

  const { images, fetchAllImages, uploadImage } = props;
  const image_count = images.images.count ? parseInt(images.images.count) : 0;
  const page_count = Math.ceil(image_count / limit);
  const mediaBaseURL = process.env.REACT_APP_MEDIA_BASE_URL;
  useEffect(() => {
    fetchAllImages({limit: limit, offset: 0});
  }, []);

  const handleChange = (e, p) => {
    setPage(p);
    fetchAllImages({limit: limit,offset: (p - 1) * limit});
  };

  const handleAddImageModel = () => {
    setOpen(true);
  };

  const handleAddImageModelClose = () => {
    setOpen(false);
  };

  const addImageHandler = async (e) => {
    e.preventDefault();
    console.log({imageData})
    try {
      await uploadImage(imageData).unwrap();
      handleAddImageModelClose();
      setImageData({
        title: "",
        height: 0,
        width: 0,
        media: "",
      })
      props.showAlert("Image Uploaded Successfully!", "success");
    } catch (rejectedValueOrSerializedError) {
      props.showAlert("Image not uploaded!", "error");
    }
  };

  const setImageValue = (e) => {
    setImageData({...imageData,[e.target.name]:e.target.value});
  };

  const handleImageChange = (e) => {
    setImageData({...imageData,[e.target.name]: e.target.files[0]});
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Grid
          container
          sx={{
            width: "100%",
            maxWidth: 700,
            margin: 0,
          }}
          justifyContent="space-between"
        >
          <Typography variant="h6" gutterBottom>
            Gallery
          </Typography>
          <Button variant="contained" onClick={handleAddImageModel}>
            Add Image
          </Button>
        </Grid>
        <Grid
          sx={{
            width: "100%",
            maxWidth: 700,
          }}
        >
          <ImageList
            cols={2}
            gap={8}
            sx={{ width: "100%", height: `${props.height}px` }}
          >
            {images.images.length !== 0
              ? images.images.results.map((item) => (
                  <ImageListItem
                    key={item.id}
                    onClick={() => props?.handleClick(item.id)}
                  >
                    <Image src={`${mediaBaseURL}${item.media_url}`} />
                    <ImageListItemBar
                      title={item.title}
                      subtitle={<span>Category: {item.category[0]}</span>}
                      position="below"
                    />
                  </ImageListItem>
                ))
              : ""}
          </ImageList>
          <Pagination
            count={page_count}
            variant="outlined"
            onChange={handleChange}
            page={page}
          />
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleAddImageModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            height: 400,
            bgcolor: "background.paper",
            // border: '2px solid #000',
            borderRadius: "5px",
            boxShadow: 24,
            p: 4,
          }}
          onSubmit={addImageHandler}
          component="form"
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginBottom: "10px" }}
          >
            Add Post
          </Typography>
          <TextField
            fullWidth
            label="Image Title"
            name="title"
            sx={{ mb: 2 }}
            value={imageData.title}
            onChange={(e) => setImageValue(e)}
          />
          <TextField
            fullWidth
            label="Height"
            name="height"
            sx={{ mb: 2 }}
            onChange={(e) => setImageValue(e)}
            value={imageData.height}
          />
          <TextField
            fullWidth
            label="Width"
            name="width"
            sx={{ mb: 2 }}
            onChange={(e) => setImageValue(e)}
            value={imageData.width}
          />
            <Grid item>
              <img src={imageData.image} alt="image file" />
            </Grid>
            <label htmlFor="contained-button-file">
              <Button variant="contained" component="span">
                Select Image
                <input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="media"
                  onChange={handleImageChange}
                />
              </Button>
            </label>

          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              size="small"
              type="submit"
              sx={{ marginTop: "10px" }}
              onClick={addImageHandler}
            >
              Save
            </Button>
            {props.postActions?.status === requestState.loading && (
              <div>loading.......</div>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    images: getAllImages(state),
    alert: state.alert
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllImages: (data) => dispatch(fetchAllImages(data)),
    uploadImage: (data) => dispatch(uploadImage(data)),
    showAlert: (message,type) => dispatch(showAlert(message,type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
