import React from "react";
import MainLayout from './components/main-layout/main-layout';
import Editor from './components/editor/editor';
import Preview from './components/preview/preview';
import MarkdownProvider from './providers/markdown-provider';
import "./style.css"
import "./stackedit.css"

const CustomMarkdownEditor = (markdown) => {
    
  return (
    <>
    {markdown ? 
        <MainLayout>
          <MainLayout.Column>
            <Editor data={markdown}/>
          </MainLayout.Column>
          <MainLayout.Column>
            <Preview />
          </MainLayout.Column>
        </MainLayout>
      : <div>Mardown Loading</div>}
      </>
    
  );
};

export default CustomMarkdownEditor;
