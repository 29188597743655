import './title-bar.css';

const TitleBar = ({ title, aside }) => (
  <div className="mb-4">
    <div className="titleBar__wrap">
      { title && <div className="mb-2">{ title }</div> }
      { aside && <div>{ aside }</div>}
    </div>
    <hr />
  </div>
);

export default TitleBar;
