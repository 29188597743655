import { createSlice } from '@reduxjs/toolkit'
import { addPost, updatePost, deletePost, fetchSinglePost, fetchAllPost, fetchPostCategory, addPostCategory } from "./postActions";
import { requestState } from "../type/type";


const allPostState = {
  post: [],
  error: "",
  status: requestState.idle
}

const singlePostState = {
  post: [],
  error: "",
  status: requestState.idle
};


const actionTypes = {
  addPost: {
    status: requestState.idle,
    error: ""
  },
  updatePost: {
    status: requestState.idle,
    error: ""
  },
  deletePost: {
    status: requestState.idle,
    error: ""
  }
}

const allCategoryState = {
  category: [],
  error: "",
  status: requestState.idle
}

const categoryState = {
  category: [],
  error: "",
  status: requestState.idle
}

const categoryActionTypes = {
  addCategory: {
    status: requestState.idle,
    error: ""
  },
  updateCategory: {
    status: requestState.idle,
    error: ""
  },
  deleteCategory: {
    status: requestState.idle,
    error: ""
  }
}

const commonPostState = {
  commonPost :  {post: singlePostState, posts: allPostState, act: actionTypes},
  commoncategory: {categories : allCategoryState, category: categoryState, act: categoryActionTypes },
}

const postSlice = createSlice({
  name: 'post',
  initialState: commonPostState,
  extraReducers: builder => {
    builder.addCase(fetchSinglePost.pending, state => {
      state.commonPost.post.status = requestState.loading
    })
    builder.addCase(fetchSinglePost.fulfilled, (state, action) => {
      state.commonPost.post.status = requestState.success
      state.commonPost.post.post = action.payload
      state.commonPost.post.error = ''
    })
    builder.addCase(fetchSinglePost.rejected, (state, action) => {
      state.commonPost.post.status = requestState.failed
      state.commonPost.post.post = []
      state.commonPost.post.error = action.error.message
    })
    builder.addCase(addPost.pending, state => {
      state.commonPost.act.addPost.status = requestState.loading
    })
    builder.addCase(addPost.fulfilled, (state, action) => {
      state.commonPost.act.addPost.status = requestState.success
      state.commonPost.act.addPost.error = ''
    })
    builder.addCase(addPost.rejected, (state, action) => {
      state.commonPost.act.addPost.status = requestState.failed
      state.commonPost.act.addPost.error = action.payload
    })
    builder.addCase(updatePost.pending, state => {
      state.commonPost.act.updatePost.status = requestState.loading
    })
    builder.addCase(updatePost.fulfilled, (state, action) => {
      state.commonPost.act.updatePost.status = requestState.success
      state.commonPost.act.updatePost.error = ''
      state.commonPost.post.post = action.payload
    })
    builder.addCase(updatePost.rejected, (state, action) => {
      state.commonPost.act.updatePost.status = requestState.failed
      state.commonPost.act.updatePost.error = action.payload
    })
    builder.addCase(deletePost.pending, state => {
      state.commonPost.act.deletePost.status = requestState.loading
    })
    builder.addCase(deletePost.fulfilled, (state, action) => {
      state.commonPost.act.deletePost.status = requestState.success
      state.commonPost.act.deletePost.error = ''
    })
    builder.addCase(deletePost.rejected, (state, action) => {
      state.commonPost.act.deletePost.status = requestState.failed
      state.commonPost.act.deletePost.error = action.payload
    })
    builder.addCase(fetchAllPost.pending, state => {
      state.commonPost.posts.status = requestState.loading
    })
    builder.addCase(fetchAllPost.fulfilled, (state, action) => {
      state.commonPost.posts.status = requestState.success
      state.commonPost.posts.post = action.payload
      state.commonPost.posts.error = ''
    })
    builder.addCase(fetchAllPost.rejected, (state, action) => {
      state.commonPost.posts.status = requestState.failed
      state.commonPost.posts.post = []
      state.commonPost.posts.error = action.payload
    })

    builder.addCase(fetchPostCategory.pending, state => {
      state.commoncategory.categories.status = requestState.loading
    })
    builder.addCase(fetchPostCategory.fulfilled, (state, action) => {
      state.commoncategory.categories.status = requestState.success
      state.commoncategory.categories.category = action.payload
      state.commoncategory.categories.error = ''
    })
    builder.addCase(fetchPostCategory.rejected, (state, action) => {
      state.commoncategory.categories.status = requestState.failed
      state.commoncategory.categories.category = []
      state.commoncategory.categories.error = action.payload
    })
    builder.addCase(addPostCategory.pending, state => {
      state.commoncategory.act.addCategory.status = requestState.loading
    })
    builder.addCase(addPostCategory.fulfilled, (state, action) => {
      state.commoncategory.act.addCategory.status = requestState.success
      state.commoncategory.categories.category = state.commoncategory.categories.category.concat(action.payload)
      state.commoncategory.act.addCategory.error = ''
    })
    builder.addCase(addPostCategory.rejected, (state, action) => {
      state.commoncategory.act.addCategory.status = requestState.failed
      state.commoncategory.act.addCategory.category = state.commoncategory.categories.category
      state.commoncategory.act.addCategory.error = action.payload
    })
    
  }
})

export const postsReducer = postSlice.reducer