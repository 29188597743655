import axios from 'axios'
import { accessToken, refreshToken, isAccessTokenExpired } from '../auth/authReduccer';

const baseURL = process.env.REACT_APP_DEV_BACKEND


const useAxios = () => {
    
    let authToken = accessToken();
    let rfToken = refreshToken();

    
    // console.log("Access Token : ", authToken)
    // console.log("Refresh Token : ", rfToken)
    
    
    const axiosInstance = axios.create({
        baseURL,
        headers:{Authorization: `Bearer ${authToken}`}
    });

    
    axiosInstance.interceptors.request.use(async req => {
        
        const isExpired = isAccessTokenExpired();
    
        if(!isExpired) return req
    
        const response = await axios.post(`${baseURL}/api/refreshToken`, {
            refresh: rfToken
          });

        //   console.log("New Access Token : ",response.data.access)
        //   console.log("New refresh Token : ",response.data.refresh)
    
        localStorage.setItem('JWT_access_token', response.data.access)
        localStorage.setItem('JWT_refresh_token', response.data.refresh)
        
        
        
        req.headers.Authorization = `Bearer ${response.data.access}`
        return req
    })
    
    return axiosInstance
}

export default useAxios;