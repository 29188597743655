import { combineReducers } from 'redux'
import alertReducer from './alert/alertReducers'
import * as fromAuth from './auth/authReduccer'
import { galleryReducer } from './gallery/galleryReducers'
import { postsReducer } from './post/postReducers'
import { authReducer, authCommonReducer } from './auth/authReduccer'


const rootReducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  postState: postsReducer,
  authState: authCommonReducer,
  galleryState: galleryReducer,
})

export const isAuthenticated =
 () => fromAuth.isAuthenticated()
export const accessToken = 
  () => fromAuth.accessToken()
export const isAccessTokenExpired =
  state => fromAuth.isAccessTokenExpired()
export const refreshToken =
  state => fromAuth.refreshToken()
export const isRefreshTokenExpired =
  state => fromAuth.isRefreshTokenExpired()
export const authErrors =
  state => fromAuth.errors(state.auth)

export default rootReducer
