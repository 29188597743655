import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  fetchSinglePost,
  fetchPostCategory,
  updatePost,
  deletePost,
  showAlert,
} from "../../../../redux";
import defaultTags from "./tags";
import Image from "mui-image";
import Gallery from "../../Gallery/Gallery";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";
import {
  getSinglePost,
  getPostCategory,
  getPostActions,
} from "../../../../redux/post/postSelectors";
import { requestState } from "../../../../redux/type/type";
import CustomMarkdownEditor from "../../../../CustomeMarkdownEditor/CustomMarkdownEditor";
import { useMarkdown } from "../../../../CustomeMarkdownEditor/providers/markdown-provider";
import { areObjectsEqual } from "../../../../Utils/stringFunctions.js";
import { useNavigate } from "react-router-dom";

const EditPost = (props) => {
  const {
    post,
    category,
    postActions,
    fetchSinglePost,
    fetchPostCategory,
    updatePost,
    deletePost,
    showAlert,
  } = props;
  const params = useParams();
  const [catValue, setCatValue] = useState([]);

  const [tag, setTag] = useState([]);
  const [postCatValue, setPostCatValue] = useState([1]);
  const postFormat = {
    post_title: "",
    post_slug: "",
    post_description: "",
    post_markdown: "",
    post_category: [],
    post_meta_keywords: [],
    post_meta_description: "",
    post_publish: false,
    post_image: "",
  };
  const [value, setValue] = useState(postFormat);
  const [inputValue, setInputValue] = useState("");
  const [postMarkdown, setPostMarkdown] = useMarkdown();

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleImageModelOpen = () => setOpen(true);
  const handleImageModelClose = () => setOpen(false);
  const mediaBaseURL = process.env.REACT_APP_MEDIA_BASE_URL;
  const [postValue, setPostValue] = useState(postFormat);

  useEffect(() => {
    fetchSinglePost(params.postId);
    fetchPostCategory();
  }, []);

  useEffect(() => {
    setValue(post.post?.data || postFormat);
  }, [post]);

  useEffect(() => {
    setPostValue({
      post_title: post.post?.data?.post_title,
      post_slug: post.post?.data?.post_slug,
      post_description: post.post?.data?.post_description,
      post_markdown: post.post?.data?.post_markdown,
      post_category: post.post?.data?.post_category,
      post_meta_keywords: post.post?.data?.post_meta_keywords,
      post_meta_description: post.post?.data?.post_meta_description,
      post_publish: post.post?.data?.post_publish,
    });
    const keywords = post.post?.data?.post_meta_keywords;
    console.log(keywords);
    setTag(
      keywords === undefined || keywords === null || keywords.length === 0
        ? []
        : keywords.split(", ")
    );
    setPostMarkdown(post.post?.data?.post_markdown || "## Title");
  }, [post, setPostMarkdown]);

  // console.log(value, postMarkdown);
  useEffect(() => {
    setCatValue(category?.category);
  }, [category]);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case ",": {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length > 0) {
          setTag([...tag, event.target.value]);
          setInputValue("");
        }
        break;
      }
      default:
    }
  };

  useEffect(() => {
    // For setting the category values
    if (post.post?.data?.post_category) {
      const selectedCategories = post.post?.data?.post_category
        .map((id) => catValue.find((category) => category.id === id))
        .filter(Boolean); // Filter out undefined values
      setPostCatValue(selectedCategories);
    }
  }, [catValue, post]);

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    // Check if there is any change in post or not
    const catCompare = postCatValue.map((key) => key.id);

    if (
      postValue &&
      postValue.post_title === value.post_title &&
      postValue.post_slug === value.post_slug &&
      postValue.post_description === value.post_description &&
      postValue.post_meta_description === value.post_meta_description &&
      postValue.post_markdown === postMarkdown &&
      areObjectsEqual(postValue.post_category, catCompare) &&
      postValue.post_meta_keywords === tag.join(", ")
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [post, value, postMarkdown, postCatValue, tag]);

  const onChange = (e) => {
    // handle input change of four fields
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handlePublish = async () => {
    // for publishing post
    await updatePost({
      id: params.postId,
      value: { post_publish: !value.post_publish },
    });
  };
  const updateImage = async (id) => {
    // for updating image
    await updatePost({ id: params.postId, value: { post_image_id: id } });
    handleImageModelClose();
  };

  const updatePostHandler = async (event) => {
    // For saving post
    event.preventDefault();
    postValue.post_title = value.post_title;
    postValue.post_slug = value.post_slug;
    postValue.post_description = value.post_description;
    postValue.post_meta_description = value.post_meta_description;
    postValue.post_markdown = postMarkdown;
    postValue.post_category = postCatValue.map((key) => key.id);
    postValue.post_meta_keywords = tag.join(", ");

    await updatePost({ id: params.postId, value: postValue });
  };

  const deletePostHandler = async () => {
    // For deleting post
    deletePost(params.postId).then((res) => {
      console.log(res);
      if (res.payload.status_code === 200) {
        navigate("/dashboard/post");
        showAlert("Post deleted", "success");
      } else {
        showAlert("Post not deleted", "error");
      }
    });
  };

  if (
    post.status !== requestState.success ||
    category.status !== requestState.success ||
    !(catValue.length > 0)
  ) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="rectangular" height={100} />
        <Skeleton variant="rounded" height={60} />
        <Skeleton variant="rectangular" height={40} />
        <Skeleton variant="rectangular" height={100} />
        <Skeleton variant="rounded" height={60} />
      </Stack>
    );
  } else {
    return (
      <>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          onSubmit={updatePostHandler}
          component="form"
        >
          <Grid
            sx={{
              width: "100%",
              maxWidth: 700,
            }}
          >
            EditPost
          </Grid>
          <Grid
            sx={{
              width: "100%",
              maxWidth: 700,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Author : {value?.post_author}
            </Typography>
          </Grid>
          <Grid
            sx={{
              width: "100%",
              maxWidth: 400,
            }}
          >
            <Image
              src={value?.post_image ? mediaBaseURL + value?.post_image : ""}
            />
            <EditIcon onClick={handleImageModelOpen} />
          </Grid>
          <Grid
            sx={{
              width: "100%",
              maxWidth: 700,
            }}
          >
            <TextField
              label="title"
              fullWidth
              id="post_title"
              value={value?.post_title || ""}
              name="post_title"
              onChange={onChange}
            />
          </Grid>
          <Grid
            sx={{
              width: "100%",
              maxWidth: 700,
            }}
          >
            <TextField
              fullWidth
              label="Slug"
              id="post_slug"
              value={value?.post_slug || ""}
              name="post_slug"
              onChange={onChange}
            />
          </Grid>
          <Grid
            sx={{
              width: "100%",
              maxWidth: 700,
            }}
          >
            <TextField
              id="outlined-multiline-static"
              label="Short Description"
              multiline
              rows={4}
              fullWidth
              value={value?.post_description || ""}
              name="post_description"
              onChange={onChange}
            />
          </Grid>
          <Grid
            sx={{
              width: "100%",
              maxWidth: 700,
            }}
          >
            <Autocomplete
              name="post_category"
              multiple
              limitTags={2}
              id="multiple-limit-tags"
              options={catValue}
              getOptionLabel={(option) => (option ? option.category_name : "")}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={postCatValue}
              onChange={(event, newValue) => setPostCatValue(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  placeholder="category"
                />
              )}
            />
          </Grid>
          <Grid
            sx={{
              width: "100%",
              maxWidth: 700,
            }}
          >
            <Autocomplete
              multiple
              freeSolo
              name="post_meta_keywords"
              id="tags-outlined"
              options={defaultTags}
              getOptionLabel={(option) => option}
              value={tag}
              onChange={(event, newValue) => setTag(newValue)}
              filterSelectedOptions
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => {
                params.inputProps.onKeyDown = handleKeyDown;
                return (
                  <TextField
                    {...params}
                    name="tags"
                    variant="outlined"
                    label="Meta Keywords"
                    placeholder="Enter Keyword"
                    margin="normal"
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
          <Grid
            sx={{
              width: "100%",
              maxWidth: 700,
            }}
          >
            <TextField
              id="outlined-multiline-static-2"
              label="Meta Description"
              name="post_meta_description"
              multiline
              rows={4}
              fullWidth
              value={value.post_meta_description || ""}
              onChange={onChange}
            />
          </Grid>
          <Grid
            sx={{
              width: "100%",
              maxWidth: 700,
            }}
          >
            {postMarkdown ? (
              <CustomMarkdownEditor markdown={value.post_markdown} />
            ) : (
              ""
            )}
          </Grid>

          <Grid
            container
            sx={{
              width: "100%",
              maxWidth: 700,
            }}
            justifyContent="space-between"
          >
            <Button variant="contained" onClick={handlePublish}>
              {value.post_publish ? "Unpublish" : "Publish"}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={deletePostHandler}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={
                !isValid ||
                postActions.updatePost?.status === requestState.loading
              }
            >
              {postActions.updatePost?.status === requestState.loading
                ? "saving..."
                : "save"}
            </Button>
          </Grid>
        </Grid>
        <Modal
          open={open}
          onClose={handleImageModelClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 700,
              bgcolor: "background.paper",
              // border: '2px solid #000',
              borderRadius: "5px",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Gallery
              height="400"
              handleClick={(postId) => updateImage(postId)}
            />
          </Box>
        </Modal>
      </>
    );
  }
};

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

const mapStateToProps = (state) => {
  return {
    post: getSinglePost(state),
    category: getPostCategory(state),
    postActions: getPostActions(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSinglePost: (id) => dispatch(fetchSinglePost(id)),
    fetchPostCategory: () => dispatch(fetchPostCategory()),
    updatePost: ({ id, value }) => dispatch(updatePost({ id, value })),
    deletePost: (id) => dispatch(deletePost(id)),
    showAlert: (message, type) => dispatch(showAlert(message, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPost);
