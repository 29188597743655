import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../redux/rootReducer'
import { useSelector } from 'react-redux'

const PrivateRoute = (Component) => {
    const flag = useSelector(() => isAuthenticated())

    return (
      
            flag ?
                Component.children
            : <Navigate replace to="/" />
       
    );
};



export default PrivateRoute;