import useAxios from '../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchAllPost = createAsyncThunk('post/getAllPost',({limit, offset},{ rejectWithValue }) => {
    const api = useAxios();
    return api
      .get(`/api/admin/private/post?limit=${limit}&offset=${offset}`)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
})


export const fetchSinglePost = createAsyncThunk('post/getSinglePost',(id, { rejectWithValue }) => {
    const api = useAxios();
    return api
      .get('/api/admin/private/post/'+id+'/')
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
})

  export const addPost = createAsyncThunk('post/addPost',(value, { rejectWithValue }) => {
    const api = useAxios();
    return api
      .post('/api/admin/private/post/',value)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
})

export const updatePost = createAsyncThunk('post/updatePost',(data, { rejectWithValue }) => {
  const {id,value} = data;
  const api = useAxios();
  return api
    .patch('/api/admin/private/post/'+id+'/',value)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
})

export const deletePost = createAsyncThunk('post/deletePost',(id, { rejectWithValue }) => {
  const api = useAxios();
  return api
    .delete('/api/admin/private/post/'+id+'/')
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
})

export const fetchPostCategory = createAsyncThunk('post/getPostCategory', (_,{rejectWithValue }) => {
    const api = useAxios();
    return api
      .get('/api/admin/private/post_category/')
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
})


export const addPostCategory = createAsyncThunk('post/addPostCategory', (value,{rejectWithValue }) => {
  const api = useAxios();
  return api
    .post('/api/admin/private/post_category', {
      category_name: value
    })
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
})
