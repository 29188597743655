import useAxios from '../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchAllImages = createAsyncThunk('gallery/fetchAllImages',({limit, offset},{ rejectWithValue }) => {
    const api = useAxios();
    return api
      .get(`api/admin/gallery/images?limit=${limit}&offset=${offset}`)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
})


export const uploadImage = createAsyncThunk('gallery/uploadImage',(data,{ rejectWithValue }) => {

  const config = {
    headers:{
      "Content-Type": "multipart/form-data;"
    },
    onUploadProgress: (progressEvent) => {
      // You can dispatch progress updates here if needed
      // let downloadCount = DownloadCount(
      //   progressEvent.timeStamp,
      //   progressEvent.total,
      //   progressEvent.loaded
      // );
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      console.log(progress)
      // Dispatch the progress or handle it as needed
      // Example: dispatch(setUploadProgress(progress));
    },
    onDownloadProgress: (progressEvent) => {
      // You can dispatch progress updates here if needed
      // const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      // console.log(progress)
      // You can dispatch the progress or handle it as needed
      // Example: dispatch(setUploadProgress(progress));

      const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
    const current = progressEvent.currentTarget.response.length

    let percentCompleted = Math.floor(current / total * 100)
    console.log('completed: ', percentCompleted)
      
    }
  };

    const api = useAxios();
    return api
      .post(`api/admin/gallery/upload_image`, data, config)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
})