import axios from 'axios'
import useAxios from '../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'
const url = process.env.REACT_APP_DEV_BACKEND

export const otpRequest = createAsyncThunk('auth/getOTP',(value,{ rejectWithValue }) => {

    return axios
      .post(`${url}/api/get_otp`,value)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
})

export const fetchToken = createAsyncThunk('auth/fetchToken',(value,{ rejectWithValue }) => {

  return axios
    .post(`${url}/api/verify_otp`,value)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
})

export const verifyTOTP = createAsyncThunk('auth/verifyTOTP',(value,{ rejectWithValue }) => {
  return axios
    .post(`${url}/api/verify_totp`,value)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
})

export const generateTOTP = createAsyncThunk('auth/generateTOTP',(_,{ rejectWithValue }) => {
  const api = useAxios();
  return api
    .post(`${url}/api/create_totp_secret_uri`)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
})

export const logoutUser = () => dispatch => {
  localStorage.removeItem('JWT_access_token');
  localStorage.removeItem('JWT_refresh_token');
}
