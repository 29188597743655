import {
  ALERT_SUCCESS,
  ALERT_RESET,
} from './alertTypes'

export const showAlert = (message, type)=>{
    return (dispatch) => {
        const payload = {
            message : message,
            type : type
        }
        dispatch(alertSuccess(payload));
        setTimeout(() => {
            dispatch(alertReset());
        }, 3000);
    }
}



export const alertSuccess = alert => {
  return {
    type: ALERT_SUCCESS,
    payload: alert
  }
}

export const alertReset = () => {
  return {
    type: ALERT_RESET
  }
}
