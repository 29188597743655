const handleAxiosError = error => {
    let message = "";
    if(!error){
      return message;
    }
    try {
        message = Object.values(error?.response.data)[0];
    } catch (e) {
      message = "There is Some error"
    }
    
      return message;
}

export default handleAxiosError