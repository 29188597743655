import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
} from "./authTypes";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { otpRequest, fetchToken, verifyTOTP, generateTOTP } from "./authAtions";
import { requestState } from "../type/type";
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  token: [],
  error: "",
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        loading: false,
        token: action.payload,
        error: "",
      };
    case LOGIN_FAILURE:
      return {
        loading: false,
        token: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const refreshTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        loading: false,
        token: action.payload,
        error: "",
      };
    case REFRESH_TOKEN_FAILURE:
      return {
        loading: false,
        token: [],
        error: action.payload,
      };
    default:
      return state;
  }
};


const authState = {
  response: [],
  error: "",
  status: requestState.idle,
};

const fetchTokenState = {
  loading: false,
  token: [],
  response: [],
  error: "",
  status: requestState.idle,
};

const generateTOTPState = {
  response: [],
  error: "",
  status: requestState.idle,
};

const commonAuthState = {
  commonAuth : {auth: authState,tokenState: fetchTokenState, generateTOTPState: generateTOTPState}
}

const authSlice = createSlice({
  name: 'post',
  initialState: commonAuthState,
  extraReducers: builder => {
    builder.addCase(otpRequest.pending, state => {
      state.commonAuth.auth.status = requestState.loading
    })
    builder.addCase(otpRequest.fulfilled, (state, action) => {
      state.commonAuth.auth.status = requestState.success
      state.commonAuth.auth.response = action.payload
      state.commonAuth.auth.error = ''
    })
    builder.addCase(otpRequest.rejected, (state, action) => {
      state.commonAuth.auth.status = requestState.failed
      state.commonAuth.auth.response = []
      state.commonAuth.auth.error = action.payload
    })
    builder.addCase(fetchToken.pending, state => {
      state.commonAuth.tokenState.status = requestState.loading
    })
    builder.addCase(fetchToken.fulfilled, (state, action) => {
      state.commonAuth.tokenState.status = requestState.success
      state.commonAuth.tokenState.token = action.payload
      state.commonAuth.tokenState.error = ''
    })
    builder.addCase(fetchToken.rejected, (state, action) => {
      state.commonAuth.tokenState.status = requestState.failed
      state.commonAuth.tokenState.response = []
      state.commonAuth.tokenState.token = []
      state.commonAuth.tokenState.error = action.payload
    })
    builder.addCase(verifyTOTP.pending, state => {
      state.commonAuth.tokenState.status = requestState.loading
    })
    builder.addCase(verifyTOTP.fulfilled, (state, action) => {
      state.commonAuth.tokenState.status = requestState.success
      state.commonAuth.tokenState.token = action.payload
      state.commonAuth.tokenState.error = ''
    })
    builder.addCase(verifyTOTP.rejected, (state, action) => {
      state.commonAuth.tokenState.status = requestState.failed
      state.commonAuth.tokenState.response = []
      state.commonAuth.tokenState.token = []
      state.commonAuth.tokenState.error = action.payload
    })  
    builder.addCase(generateTOTP.pending, state => {
      state.commonAuth.generateTOTPState.status = requestState.loading
    })
    builder.addCase(generateTOTP.fulfilled, (state, action) => {
      state.commonAuth.generateTOTPState.status = requestState.success
      state.commonAuth.generateTOTPState.response = action.payload
      state.commonAuth.generateTOTPState.error = ''
    })
    builder.addCase(generateTOTP.rejected, (state, action) => {
      state.commonAuth.generateTOTPState.status = requestState.failed
      state.commonAuth.generateTOTPState.response = []
      state.commonAuth.generateTOTPState.error = action.payload
    })   
  }
})


export function accessToken() {
  try {
    let accessToken = localStorage.getItem("JWT_access_token");
    if (accessToken) {
      return accessToken;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export function refreshToken() {
  try {
    let refreshToken = localStorage.getItem("JWT_refresh_token");
    if (refreshToken) {
      return refreshToken;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export function isAccessTokenExpired() {
  try {
    let accessToken = localStorage.getItem("JWT_access_token");
    if (accessToken) {
      return dayjs.unix(jwt_decode(accessToken).exp).diff(dayjs()) < 1;
    }
  } catch (error) {
    console.log(error);
  }
  return true;
}
export function isRefreshTokenExpired() {
  try {
    let refreshToken = localStorage.getItem("JWT_refresh_token");
    if (refreshToken) {
      return dayjs.unix(jwt_decode(refreshToken)?.exp).diff(dayjs()) < 1;
    }
  } catch (error) {
    console.log(error);
  }
  return true;
}
export function isAuthenticated() {
  return !isRefreshTokenExpired();
}
export function errors(state) {
  return state.error;
}

export const authCommonReducer = authSlice.reducer