import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function TOTPInstructions() {
  return (
    <div>
      <Typography variant="h5">Setting Up Two-Factor Authentication (2FA) with Time-Based One-Time Password (TOTP)</Typography>
      <Typography variant="body1">
        <strong>Step 1: Install an Authenticator App</strong>
        <br />
        To use 2FA, you'll need to install an authenticator app on your mobile device. Some popular options include:
        <ul>
          <li>
            <Link href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US">Google Authenticator (Android)</Link>
          </li>
          <li>
            <Link href="https://apps.apple.com/us/app/google-authenticator/id388497605">Google Authenticator (iOS)</Link>
          </li>
          <li>
            <Link href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en&gl=US">Microsoft Authenticator (Android)</Link>
          </li>
          <li>
            <Link href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458">Microsoft Authenticator (iOS)</Link>
          </li>
          <li>
            <Link href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en&gl=US">Authy (Android)</Link>
          </li>
          <li>
            <Link href="https://apps.apple.com/us/app/authy/id494168017">Authy (iOS)</Link>
          </li>
        </ul>
      </Typography>
      <Typography variant="body1">
        <strong>Step 2: Scan the QR Code</strong>
        <br />
        Once you've installed an authenticator app, you can set up 2FA for your account:
        <ol>
          <li>Open the authenticator app on your mobile device.</li>
          <li>In the app, tap the "+" button to add a new account.</li>
          <li>Select "Scan a QR code" and point your device's camera at the QR code displayed on our website.</li>
        </ol>
        The authenticator app will now generate a new, unique code every 30 seconds for your account.
      </Typography>
      <Typography variant="body1">
        <strong>Step 3: Enter the Code</strong>
        <br />
        After scanning the QR code:
        <ol>
          <li>The app will display a six-digit code. Enter this code into the input field on our website.</li>
          <li>Click "Verify" to complete the setup process.</li>
        </ol>
        Now, every time you log in to your account, you'll be asked to enter the current code from your authenticator app. This adds an extra layer of security to your account.
      </Typography>
    </div>
  );
}

export default TOTPInstructions;