import React,{ useState } from 'react'
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import PostTable from "./PostTable";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { addPost } from '../../../redux';
import { userId } from '../../Utils/userDetails'
import { requestState } from '../../../redux/type/type';
import { showAlert } from '../../../redux';
import { getPostActions, getSinglePost } from '../../../redux/post/postSelectors';
import { useNavigate } from 'react-router-dom';

const Post = (props) => {
  const { addPost } = props;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  
  const handleAddPostModelOpen = () => setOpen(true);
  const handleAddPostModelClose = () => setOpen(false);

  

  const addPostHandler = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let post_title = data.get('post_title');
    let post_slug = post_title.split(' ').join('-');
    const value = {
      post_title: post_title,
      post_slug: post_slug,
      post_author_id: userId(),
      post_updated_by_id: userId(),
    }
    try{
        let res = await addPost(value).unwrap();
        handleAddPostModelClose();
        navigate('/dashboard/post/'+res.data.id);
        props.showAlert("Post Added Successfully!","success");
    }catch (rejectedValueOrSerializedError) {
      props.showAlert("Post Not Added!","error");
    }

  }

  return (
    <>
    <Grid container spacing={3} justifyContent='center'>
        <Grid
          container
          sx={{
            width: "100%",
            maxWidth: 700,
            margin:0,
          }}
          justifyContent="space-between"
        >
          <Typography variant="h6" gutterBottom>
            Posts
          </Typography>
          <Button variant="contained" onClick={handleAddPostModelOpen}>Add Post</Button>
        </Grid>
        <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <PostTable />
        </Paper>
      </Grid>
    </Grid>
    <Modal
        open={open}
        onClose={handleAddPostModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
  }}
        onSubmit={addPostHandler}
        component="form">
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{marginBottom:'10px'}}>
            Add Post
          </Typography>
          <TextField
            fullWidth
            label="Post Title"
            id="post_title"
            defaultValue=""
            name="post_title"
          />
          <Box display='flex' justifyContent='flex-end'>
          <Button variant="contained" size="small" type="submit" sx={{marginTop:'10px'}}>Save</Button>
          {props.postActions?.status === requestState.loading && 
          <div>loading.......</div>
          }
          </Box>
        </Box>
      </Modal>
    </>
  )
}

const mapStateToProps = state => {
  return {
    post: getSinglePost(state),
    postActions: getPostActions(state)
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    addPost: (value) => dispatch(addPost(value)),
    showAlert: (message,type) => dispatch(showAlert(message,type)),
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Post);
