import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import { getPostCategory } from "../../../../redux/post/postSelectors";
import { addPostCategory, fetchPostCategory,  showAlert } from "../../../../redux";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { requestState } from "../../../../redux/type/type";

const PostCategory = (props) => {
  const [open, setOpen] = useState(false);
  const handleCategoryModelOpen = () => setOpen(true);
  const handleCategoryModelClose = () => setOpen(false);

  const { category, fetchPostCategory, addPostCategory } = props;
  useEffect(() => {
    fetchPostCategory();
  }, []);

  const addPostCategoryHandler = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let cat = data.get('category');
    
    try{
      await addPostCategory(cat).unwrap();
      handleCategoryModelClose();
      props.showAlert("Category added successfully!","success");
  }catch (rejectedValueOrSerializedError) {
      props.showAlert("Category not added!","error");
  }


  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
  };

  if (category.status === requestState.loading) {
    return <div>Loading....</div>;
  } else {
    return (
        <>
      <Grid
        container
        spacing={3}
        alignItems="center"
        columns={12}
      >
        <Grid
          sx={{
            width: "100%",
            margin:0,
            display:"flex"
          }}
          justifyContent="space-between"
        >
          <Typography variant="h6" gutterBottom>
            Categories
          </Typography>
          <Button variant="contained" onClick={handleCategoryModelOpen}>Add Category</Button>
        </Grid>
        {category.category.map((row) => (
        <Grid md={4} xs={12} sm={6} mobile={12} key={row.id}>
          <Paper elevation={3} sx={{  padding:"25px 0px", display:"flex", justifyContent:"center", flexDirection:"column", alignItems: "center" }}>
            <Typography variant="subtitle">{row?.category_name}</Typography>
            <Typography variant="subtitle2">{row?.view_count}</Typography>
          </Paper>
        </Grid>
        ))}
      </Grid>
      <Modal
        open={open}
        onClose={handleCategoryModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}
        onSubmit={addPostCategoryHandler}
        component="form">
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{marginBottom:'10px'}}>
            Add Category
          </Typography>
          <TextField
            fullWidth
            label="category"
            id="category"
            defaultValue=""
            name="category"
          />
          <Box display='flex' justifyContent='flex-end'>
          <Button variant="contained" size="small" type="submit" sx={{marginTop:'10px'}}>Save</Button>
          </Box>
        </Box>
      </Modal>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    category: getPostCategory(state),
    alert: state.alert
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPostCategory: () => dispatch(fetchPostCategory()),
    addPostCategory: (category) => dispatch(addPostCategory(category)),
    showAlert: (message,type) => dispatch(showAlert(message,type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostCategory);
