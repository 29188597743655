import { Routes, Route } from "react-router-dom";
import SignIn from "./components/Authentication/SignIn";
import Dashboard from "./components/Dashboard/Dashboard";
import DashboardLayout from "./components/Dashboard/DashboardLayout"
import PrivateRoute from "./route/PrivateRoute";
import EditPost from "./components/Dashboard/Post/EditPost/EditPost";
import Post from "./components/Dashboard/Post/Post";
import PostCategory from "./components/Dashboard/Post/PostCategory/PostCategory";
import Gallery from "./components/Dashboard/Gallery/Gallery";
import CustomMarkdownEditor from "./CustomeMarkdownEditor/CustomMarkdownEditor";
import MarkdownProvider from "./CustomeMarkdownEditor/providers/markdown-provider";
import DashboardSetting from "./components/Dashboard/Setting/DashboardSetting";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="" element={<SignIn />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashboardLayout component={<Dashboard />} />
              
            </PrivateRoute>
          }
        />
        <Route path="/dashboard/post" element={
          <PrivateRoute>
          <DashboardLayout component={<Post />} /> 
        </PrivateRoute>
        } />
        <Route path="/dashboard/post/:postId" element={
          <PrivateRoute>
          <DashboardLayout component={<MarkdownProvider><EditPost /></MarkdownProvider>} /> 
        </PrivateRoute>
        } />
         <Route path="/dashboard/post_category" element={
          <PrivateRoute>
          <DashboardLayout component={<PostCategory />} /> 
        </PrivateRoute>
        } />
        <Route
          path="/dashboard/gallery"
          element={
            <PrivateRoute>
              <DashboardLayout component={<Gallery />} /> 
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/settings"
          element={
            <PrivateRoute>
              <DashboardLayout component={<DashboardSetting />} /> 
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/md_editor"
          element={
            <PrivateRoute>
              <DashboardLayout component={<MarkdownProvider><CustomMarkdownEditor markdown="# Test Title"/></MarkdownProvider>} />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
