import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TOTPQRCode from "./TOTPQRCode";
import { connect } from "react-redux";
import { generateTOTP } from "../../../redux";
import { getGenerateTOTPState } from "../../../redux/auth/authSelectors";
import TOTPInstructions from "./TOTPDocumentation";

const DashboardSetting = (props) => {
    const { generateTOTP } = props;

  const [QRCode, setQRCode] = useState(null);


  console.log(QRCode);
  const generateQRCode = async () => {
    await generateTOTP();
    try{
        const data = await generateTOTP().unwrap();
        if(data.data?.secret_uri){
            setQRCode(data.data?.secret_uri);
        }
        props.showAlert("ORCode Generated!","success");
    }catch (rejectedValueOrSerializedError) {
      props.showAlert("Failed to generate QR Code","error");
    }
  };

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Grid
        container
        sx={{
          width: "100%",
          maxWidth: 700,
          margin: 0,
        }}
        justifyContent="space-between"
      >
        <Typography variant="h3" gutterBottom>
          Dashboard
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          width: "100%",
          maxWidth: 700,
          margin: 0,
        }}
        justifyContent="space-between"
      >
        <Typography variant="h6" gutterBottom>
          Do you want to setup TOTP? Click on button generate QR to setup TOTP
        </Typography>
        {!QRCode && <Button variant="contained" onClick={generateQRCode}>
          Generate QR Code
        </Button>}
      </Grid>
      {QRCode && (
        <Grid
          container
          sx={{
            width: "100%",
            maxWidth: 700,
            margin: 0,
          }}
          justifyContent="space-between"
        >
          <TOTPQRCode QRCode={QRCode}/>
        </Grid>
      )}
      {QRCode && (
        
        <Grid
          container
          sx={{
            width: "100%",
            maxWidth: 700,
            margin: 0,
          }}
          justifyContent="space-between"
        >
          <TOTPInstructions />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
    return {
        generateTOTPState: getGenerateTOTPState(state),
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        generateTOTP: (data) => dispatch(generateTOTP(data)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(DashboardSetting);

