import { requestState } from '../type/type';
import { createSlice } from '@reduxjs/toolkit'
import { fetchAllImages, uploadImage } from './galleryActions';

const allImagesState = {
  images: [],
  error: "",
  status: requestState.idle
}

const singleImageState = {
  image: [],
  error: "",
  status: requestState.idle,
  uploadProgress: 0
}

const galleryState = {
  images: allImagesState,
  image: singleImageState,
}


const gallerySlice = createSlice({
  name: 'gallery',
  initialState: galleryState,
  extraReducers: builder => {
    builder.addCase(fetchAllImages.pending, state => {
      state.images.status = requestState.loading
    })
    builder.addCase(fetchAllImages.fulfilled, (state, action) => {
      state.images.status = requestState.success
      state.images.images = action.payload
      state.images.error = ''
    })
    builder.addCase(fetchAllImages.rejected, (state, action) => {
      state.images.status = requestState.failed
      state.images.images = []
      state.images.error = action.error.message
    })
    builder.addCase(uploadImage.pending, state => {
      state.image.status = requestState.loading
    })
    builder.addCase(uploadImage.fulfilled, (state, action) => {
      state.image.status = requestState.success
      state.image.image = action.payload
      state.image.error = ''
    })
    builder.addCase(uploadImage.rejected, (state, action) => {
      state.image.status = requestState.failed
      state.image.error = action.error.message
    })
  
  }
})

  
export const galleryReducer = gallerySlice.reducer