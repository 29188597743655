import {
    ALERT_SUCCESS,
    ALERT_RESET,
  } from "./alertTypes";
  
  const initialState = {
    message: "",
    type : "",
  };
  
  const alertReducer = (state = initialState, action) => {
    switch (action.type) {
      case ALERT_SUCCESS:
        return {
          message: action.payload.message,
          type: action.payload.type,
        };
      case ALERT_RESET:
        return {
            message: "",
            type : ""
        };
      
      default:
        return state;
    }
  };
  
  
  
  export default alertReducer;
  