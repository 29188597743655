const _ = require('lodash');
export const isURL = (str) => {
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+\.[a-z.]{2,6}|[\d.]+)(:[\d]*)?([/\\?#].*)?$/i;
    return urlPattern.test(str);
  }


export function areObjectsEqual(obj1, obj2) {
    return _.isEqual(obj1, obj2);
}

export const formatDate = (date) => {
  if (!date) return '';
  const d = new Date(date);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return d.toLocaleDateString(undefined, options);
}