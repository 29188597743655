import React,{ useState } from 'react';
import { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux'
import { fetchToken, otpRequest, verifyTOTP } from '../../redux'
import {authErrors, isAuthenticated} from '../../redux/rootReducer'
import { useNavigate } from "react-router-dom";
import Modal from '@mui/material/Modal';
import { getAuthState, getTokenState } from '../../redux/auth/authSelectors';
import handleAxiosError from '../../redux/utils/handleAxiosError';
import setAuthToken from '../../redux/auth/setAuthToken';
import { showAlert, alertReset } from '../../redux';
import { Snackbar } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.codingnap.com/">
        Codingnap
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

function SignIn(props) {
  const {isAuthenticated} = props;
  const [open, setOpen] = useState(false);
  const handleOTPModelOpen = () => setOpen(true);
  const handleOTPModelClose = () => setOpen(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = React.useState('')
  const [isTOTPSet, setIsTOTPSet] = useState(false);
  const [isSendOTP, setIsSendOTP] = useState(true);

  const handleOTPChange = (newValue) => {
    setOtp(newValue);
  }  

  useEffect(() => {
    setError(handleAxiosError(props?.authState?.error));
  }, [props.authState])

  const handleVerifyTOTP = async (event) => {
    event.preventDefault();
    try{
      const response = await props.verifyTOTP({email,password,otp}).unwrap();
      localStorage.setItem('JWT_access_token',response.data.access)
      localStorage.setItem('JWT_refresh_token',response.data.refresh)
      setAuthToken(response.data.access);
      navigate('/dashboard');
    }catch(rejectedValueOrSerializedError){
      props.showAlert("TOTP didn't match!","error");
    }
  }
  

  const handleOTPSubmit = async (event) => {
    event.preventDefault();
    try{
      const response = await props.fetchToken({email,otp}).unwrap();
      localStorage.setItem('JWT_access_token',response.access)
      localStorage.setItem('JWT_refresh_token',response.refresh)
      setAuthToken(response.access);
      navigate('/dashboard');
    }catch(rejectedValueOrSerializedError){
      props.showAlert("OTP didn't match!","error");
    }
  }

  console.log(email,password);


  const handleGetOTP = async (event) => {
    event.preventDefault();    
      try{
        await props.otpRequest({email,password, get_otp: true}).unwrap();
        setIsSendOTP(true);
        console.log({email,password, get_otp: true})
        props.showAlert("OTP Sent Successfully!","success");
    }catch (rejectedValueOrSerializedError) {
      props.showAlert("OTP not sent!","error");
      console.log(rejectedValueOrSerializedError)
    }
    
  };


const handleSubmit = async (event) => {
    event.preventDefault();
    // handleOTPModelOpen();
    
      try{
        let data = await props.otpRequest({email,password}).unwrap();
        // console.log(data);
        if(data.data?.is_totp_set) {
          setIsTOTPSet(true);
          
          setIsSendOTP(false);
        }
        handleOTPModelOpen();
        // props.showAlert("User Verified!","success");
    }catch (rejectedValueOrSerializedError) {
      props.showAlert("User not verified","error");
      console.log(rejectedValueOrSerializedError)
    }
    
  };

  let navigate = useNavigate();
  useEffect(() => {
    if(isAuthenticated) {
      return navigate("/dashboard")    
    } 
  }, [isAuthenticated, navigate])

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.alertReset();
  };
  
  return (
    <ThemeProvider theme={theme}>
      {props.alert.message.length > 0 && (
        <Snackbar
          open={props.alert.message.length > 0}
          onClose={handleCloseAlert}
        >
          <Alert onClose={handleCloseAlert} severity={props.alert.type}>
            {props.alert.message}
          </Alert>
        </Snackbar>
      )}
      
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
              
            </Grid>
            <Grid item>
               {error.length > 0 && <Alert severity="error">{error}!</Alert>}
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      <Modal
        open={open}
        onClose={handleOTPModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          // border: '2px solid #000',
          borderRadius: '5px',
          boxShadow: 24,
          p: 4,
        }}
        // onSubmit={handleOTPSubmit}
        component="form">
          <Typography id="modal-modal-title" variant="h6" component="h3" sx={{marginBottom:'10px'}}>
            {isSendOTP ? "Enter OTP" : "Enter TOTP"}
          </Typography>
          <MuiOtpInput length={6} value={otp} onChange={handleOTPChange}/>
          <Box display='flex' justifyContent='space-between'>
          {!isSendOTP && isTOTPSet && <Button variant="text" onClick={handleGetOTP}>
           send otp to email 
          </Button>}
          {isSendOTP && isTOTPSet && <Button variant="text" onClick={() => setIsSendOTP(false)}>
            Enter TOTP
          </Button>}
          {isSendOTP && <Button variant="contained" size="small" type="button" sx={{marginTop:'10px'}} onClick={handleOTPSubmit}>Verify</Button>}
          {!isSendOTP && isTOTPSet && <Button variant="contained" size="small" type="button" sx={{marginTop:'10px'}} onClick={handleVerifyTOTP}>Verify</Button>}

          </Box>
          
        </Box>
      </Modal>
    </ThemeProvider>
  );
}


const mapStateToProps = state => {
  return {
    authState: getAuthState(state),
    tokenState: getTokenState(state),
    isAuthenticated: isAuthenticated(),
    authErrors : authErrors(state),
    alert: state.alert,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchToken: (value) => dispatch(fetchToken(value)),
    otpRequest: (data) => dispatch(otpRequest(data)),
    showAlert: (message,type) => dispatch(showAlert(message,type)),
    alertReset: () => dispatch(alertReset()),
    verifyTOTP: (value) => dispatch(verifyTOTP(value)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn)

